import { OptionModel } from "@/models/interface/common.interface";
import { BASIC_FEATURE } from "../enums/option-sales.enum";

export const OPTION_BASIC_INVOICE_TERM: OptionModel[] = [
    {
        label: '30',
        value: 30
    },
    {
        label: '60',
        value: 60
    },
    {
        label: '90',
        value: 90
    }
];

export const OPTION_BASIC_FEATURE: OptionModel[] = [
    {
        value: BASIC_FEATURE.QUOTATION_SALES_ORDER
    },
    {
        value: BASIC_FEATURE.SALES_ORDER_RETURN
    },
    {
        value: BASIC_FEATURE.SALES_JOIN_INVOICE
    },
    {
        value: BASIC_FEATURE.SALES_DISABLE_SELL_NOT_ENOUGH_QTY
    },
];

export const OPTION_SALES_COMMISION: OptionModel[] = [
    {
        label: 'All Invoices',
        value: 0
    },
    {
        label: 'Paid Invoices',
        value: 1
    }
];
