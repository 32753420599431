





































































































import { IOption } from "@/models/interface-v2/common.interface";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { masterServices } from "@/services/master.service";
import Vue, { PropType } from "vue";
import {
  OPTION_BASIC_INVOICE_TERM,
  OPTION_BASIC_FEATURE,
  OPTION_SALES_COMMISION,
} from "./constant/optionsales.constant";
import { BASIC_FEATURE } from "./enums/option-sales.enum";
import { COMPONENT_NAME } from "./Preferences.vue";
export interface IPrefSales {
  sales_top: string;
  basic: string[];
  sales_commision_counting_from: string;
}
export default Vue.extend({
  name: "PreferencesSales",
  props: {
    firstLoad: Boolean,
    dataFirstLoad: {} as PropType<IPrefSales>,
  },
  data() {
    return {
      optBasicInvoiceTerm: [] as IOption[],
      optBasicFeature: OPTION_BASIC_FEATURE,
      // optSalesCommision: OPTION_SALES_COMMISION,
      optSalesCommision: [] as ResponseListMaster[],
      salesCheckedList: [],
      valPref: {
        sales_top: "0",
        basic: [] as string[],
        sales_commision_counting_from: "" as string,
      } as IPrefSales,
    };
  },
  watch: {
    dataFirstLoad: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstLoad && newValue) {
          this.valPref.sales_top = newValue.sales_top;
          this.valPref.basic = newValue.basic;
          this.valPref.sales_commision_counting_from =
            newValue.sales_commision_counting_from;
          this.$emit("onChangeLoad", {
            name: COMPONENT_NAME.PREF_SALES,
            val: true,
          });
        }
      },
    },
  },
  created() {
    masterServices.listMaster({ name: "TOP" }).then((responseTop) => {
      this.optBasicInvoiceTerm = responseTop.map(x => {
        return { key: this.getTOPLabel(x.value), value: x.value };
      });
    });
    const paramsSalesCommision: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "SALES_COMMISION",
    };
    masterServices
      .listMaster(paramsSalesCommision)
      .then((responseSalesCommision) => {
        this.optSalesCommision = responseSalesCommision;
      });
  },
  methods: {
    getTOPLabel(top: string): string {
      if (top === "0") {
        return "lbl_cash_on_delivery";
      } else if (top === "-1") {
        return "lbl_cash_on_advance";
      } else {
        return top;
      }
    },
    generateLabel(key: string | BASIC_FEATURE): string {
      if (key === BASIC_FEATURE.QUOTATION_SALES_ORDER)
        return this.$t("lbl_sales_quotation_and_sales_order").toString();
      else if (key === BASIC_FEATURE.SALES_ORDER_RETURN)
        return this.$t("lbl_sales_order_return").toString();
      else if (key === BASIC_FEATURE.SALES_JOIN_INVOICE)
        return this.$t("lbl_join_invoice").toString();
      else if (key === BASIC_FEATURE.SALES_DISABLE_SELL_NOT_ENOUGH_QTY)
        return this.$t("lbl_disable_sales_when_not_enough_qty").toString();
      return key;
    },
    onChangeCommision(value, option): void {
      this.valPref.sales_commision_counting_from = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_SALES,
        val: this.valPref,
      });
    },
    onChangeTop(value, option): void {
      this.valPref.sales_top = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_SALES,
        val: this.valPref,
      });
    },
    onChecked(checkedValues): void {
      this.valPref.basic = checkedValues;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_SALES,
        val: this.valPref,
      });
    },
  },
});
