var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                { attrs: { bordered: false } },
                [
                  _c(
                    "a-space",
                    { attrs: { direction: "vertical" } },
                    [
                      _c("p", { staticClass: "text-subtitle-1" }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_basic_feature")) + " ")
                      ]),
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 14, xs: 8, sm: 14, md: 14 } },
                            [
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("lbl_basic_invoice_term")))
                              ])
                            ]
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 8, xs: 14, sm: 14, md: 8 } },
                            [
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "200px" },
                                  attrs: { placeholder: "Select" },
                                  on: { change: _vm.onChangeTop },
                                  model: {
                                    value: _vm.valPref.sales_top,
                                    callback: function($$v) {
                                      _vm.$set(_vm.valPref, "sales_top", $$v)
                                    },
                                    expression: "valPref.sales_top"
                                  }
                                },
                                _vm._l(_vm.optBasicInvoiceTerm, function(data) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: data.value,
                                      attrs: { value: data.value }
                                    },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  data.value === "0"
                                                    ? _vm.$t(data.key)
                                                    : (data.value === "-1"
                                                        ? _vm.$t(
                                                            "lbl_cash_in_advance"
                                                          )
                                                        : _vm.$t(
                                                            "lbl_top_in_day",
                                                            {
                                                              value: data.value
                                                            }
                                                          )) || "-"
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                data.value === "0"
                                                  ? _vm.$t(data.key)
                                                  : (data.value === "-1"
                                                      ? _vm.$t(
                                                          "lbl_cash_in_advance"
                                                        )
                                                      : _vm.$t(
                                                          "lbl_top_in_day",
                                                          { value: data.value }
                                                        )) || "-"
                                              ) +
                                              " "
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("a-checkbox-group", {
                            staticClass: "check-basic",
                            attrs: { options: _vm.optBasicFeature },
                            on: { change: _vm.onChecked },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function(ref) {
                                  var value = ref.value
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(_vm.generateLabel(value)))
                                  ])
                                }
                              }
                            ]),
                            model: {
                              value: _vm.valPref.basic,
                              callback: function($$v) {
                                _vm.$set(_vm.valPref, "basic", $$v)
                              },
                              expression: "valPref.basic"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 14, xs: 8, sm: 14, md: 14 } },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("lbl_sales_commision_counting_from")
                                  )
                                )
                              ])
                            ]
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 8, xs: 14, sm: 14, md: 8 } },
                            [
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "200px" },
                                  attrs: { placeholder: "Select" },
                                  on: { change: _vm.onChangeCommision },
                                  model: {
                                    value:
                                      _vm.valPref.sales_commision_counting_from,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.valPref,
                                        "sales_commision_counting_from",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "valPref.sales_commision_counting_from"
                                  }
                                },
                                _vm._l(_vm.optSalesCommision, function(opt) {
                                  return _c(
                                    "a-select-option",
                                    { key: opt.id, attrs: { value: opt.id } },
                                    [_vm._v(" " + _vm._s(opt.value) + " ")]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }